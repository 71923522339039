/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import Link from 'next/link'
import { openChat } from '@/utils/chat'
import { StarIcon } from '@heroicons/react/20/solid'
import { G2Icon } from '../core/icons/G2Icon'
import { StarIcon as OutlineStar } from '@heroicons/react/24/outline'
import onBlackKumohqLogo from '@/images/logos/on-black-kumohq-logo.png'
import Image from 'next/image'
const navigation = {
  solutions: [
    { name: 'Sign Up', href: '/signup/' },
    { name: 'Log in', href: '/login/' },
    { name: 'Savings calculator', href: '/savings-calculator/' },
    {
      name: 'API Docs',
      href: 'https://campaignhq.docs.apiary.io/',
      external: true,
    },
    { name: 'Email verifier tool', href: '/email-verifier/' },
    { name: 'Email marketing AI editor', href: '/email-marketing-ai-editor/' },
  ],
  support: [
    { name: 'Pricing', href: '/pricing/' },
    {
      name: 'Help Center',
      href: 'https://campaignhq.freshdesk.com',
      external: true,
    },
    { name: 'Chat with us', href: '#', callFunc: true, onClick: openChat },
  ],
  company: [
    { name: 'About', href: '/about-us/' },
    {
      name: 'Contact us',
      href: '/contact-us/',
      external: false,
    },
    { name: 'Blog', href: 'https://blog.campaignhq.co', external: true },
    { name: 'FAQs', href: '/faqs/', external: false },
  ],
  legal: [
    { name: 'Privacy policy', href: '/legal/privacy-policy/' },
    { name: 'Terms of use', href: '/legal/terms-of-use/' },
    { name: 'Acceptable use policy', href: '/legal/acceptable-use-policy/' },
  ],
  resources: [
    {
      name: 'What is email segmentation and how does it boost coversion ?',
      href: '/resources/what-is-email-segmentation-and-how-it-boost-conversion/',
    },
    {
      name: 'Boost website traffic with email marketing',
      href: '/blogs/boost-website-traffic-with-email-marketing/',
    },
    // { name: "Mailchimp alternative", href: "/mailchimp-alternative/" },
    {
      href: '/blogs/a-comprehensive-guide-to-choosing-the-best-email-marketing/',
      name: 'A comprehensive guide to choosing the best email marketing software',
    },
    {
      href: '/blogs/unleashing-the-power-of-automation-tools/',
      name: 'Elevating conversion rates: Unleashing the power of email automation tools',
    },
    { name: 'Emailoctopus Alternative', href: '/emailoctopus-alternative/' },
    {
      name: 'Guide Email Marketing for Agencies',
      href: '/blogs/Guide-Email-Marketing-for-Agencies/',
    },
    {
      name: 'The ultimate email checklist for a successful campaign',
      href: '/blogs/email-checklist-for-every-campaign/',
    },
    {
      name: 'Email Marketing Trends 2024',
      href: '/blogs/email-marketing-trends-2024/',
    },
  ],
  customers: [
    {
      name: 'Product teams',
      href: '/customers/product-teams/',
    },
    {
      name: 'Small businesses',
      href: '/customers/small-businesses/',
    },
    {
      name: 'Bloggers',
      href: '/customers/bloggers/',
    },
    {
      name: 'Authors',
      href: '/customers/authors/',
    },
    {
      name: 'Ecommerce',
      href: '/customers/ecommerce/',
    },
    {
      name: 'Agencies',
      href: '/customers/agencies/',
    },
    {
      name: 'Newsletters',
      href: '/customers/newsletters/',
    },
  ],
  // https://tailwind-elements.com/docs/standard/components/social-buttons/
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/campaignhq.co',
      icon: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
        </svg>
      ),
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/campaignhq',
      icon: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/campaignhqco',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    // {
    //   name: 'YouTube',
    //   href: '#',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
  ],
}
// import TrustpilotWidget from '../TrustPilotWidget'
export default function Footer() {
  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-20 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Product
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      {item.external ? (
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                          target="_blank"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Support
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      {item.external ? (
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                          target="_blank"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          href={item.href}
                          onClick={item.onClick}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Company
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      {item.external ? (
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                          target="_blank"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Legal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      {item.external ? (
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                          target="_blank"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mt-10 md:mt-0">
              <h3 className="text-sm font-semibold leading-6 text-white">
                Resources
              </h3>
              <ul role="list" className="mt-6 space-y-4">
                {navigation.resources.map((item) => (
                  <li key={item.name}>
                    {item.external ? (
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        target="_blank"
                      >
                        {item.name}
                      </a>
                    ) : (
                      <Link
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-10 md:mt-0">
              <h3 className="text-sm font-semibold leading-6 text-white">
                Build for
              </h3>
              <ul role="list" className="mt-6 space-y-4">
                {navigation.customers.map((item) => (
                  <li key={item.name}>
                    {item.external ? (
                      <a
                        href={item.href}
                        alt={item.name}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        target="_blank"
                      >
                        {item.name}
                      </a>
                    ) : (
                      <Link
                        href={item.href}
                        alt={item.name}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="text-white">
              <a
                href="https://www.g2.com/products/campaignhq-campaignhq/reviews"
                className="mx-auto mb-10 flex flex-col items-center space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0"
              >
                <div className="flex">
                  <StarIcon className="mx-auto h-auto w-6  text-[#ff492c]" />
                  <StarIcon className="mx-auto h-auto w-6  text-[#ff492c]" />
                  <StarIcon className="mx-auto h-auto w-6  text-[#ff492c]" />
                  <StarIcon className="mx-auto h-auto w-6  text-[#ff492c] " />
                  <div className="relative overflow-hidden">
                    <OutlineStar className="mx-auto h-auto w-6  text-[#ff492c]" />
                    <div className="absolute  top-0 z-10 w-3 overflow-hidden text-[#ff492c]">
                      <StarIcon className="text-yellow mx-auto h-auto  w-6 " />
                    </div>
                  </div>
                </div>
                <div>Rated 4.6/5 on</div>
                <G2Icon
                  className="mx-auto h-auto w-[45px]  text-[#ff492c]"
                  title="CampaignHQ is rated 4.6 on G2"
                />
              </a>
            </div>
          </div>

          <div className="mt-10 xl:mt-0">
            <h3 className="text-sm font-semibold leading-6 text-white">
              Subscribe to our newsletter
            </h3>
            <p className="mt-2 text-sm leading-6 text-gray-300">
              The latest product updates, articles, and resources, sent to your
              inbox monthly.
            </p>
            <form className="mt-6 sm:flex sm:max-w-md">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                placeholder="Enter your email"
              />
              <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-500 hover:text-gray-400"
                target="_blank"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          {/* <TrustpilotWidget /> */}
          <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
            &copy; {new Date().getFullYear()} KumoHQ Software Services LLP. All rights reserved.
          </p>
          <a
            href="https://www.kumohq.co"
            className="mt-8 flex items-center space-x-2 text-sm text-gray-400 md:order-1 md:mt-0"
          >
            <div className="mt-1">A product by</div>
            <Image
              src={onBlackKumohqLogo}
              alt="KumoHQ"
              width={90}
              height={20}
            />
          </a>
        </div>
      </div>
    </footer>
  )
}
